<template>
  <div class="content-page ">
    <div class="px-4">
      <div class="user-info-wallet-separator"></div>
    </div>

    <div class="home-content">
      <div class="home-main-content">


        <!-- username and buying coin button section -->
        <div class="d-flex justify-content-between px-4 align-items-center">

          <div class="username_and_coin">
            <el-button type="text" @click="goToRoute('profile')">
              <img :src="require('@/assets/user.svg')" alt="goalpro" />
              <span class="user-name-text">{{fullName}}</span>
            </el-button>
          </div>

          <!--
          <div>
            <button  class="btn buying_coin "  @click="openGetCoins()">
              <img :src="require('@/assets/coins.svg')" alt="coins" />
              <span class="mx-1">Buy Coin</span>
            </button>
          </div> -->


        </div>
        <!-- end username and buying coin button section -->


        </div>
        <!-- end home-main-content  -->



        <!-- user information -->

        <div class="d-flex justify-content-between px-3 align-items-center mb-2">

          <div class="col-4 ">
                <div class="card-info" :class="this.$i18n.locale == 'en' ? 'text-start' : 'text-end'" @click="goToRoute('ranking')">
            <div class="card-body">
              <h6 class="card-title">{{ isSubscribed ? ranking: '-' }}</h6>
              <h6 class="card-subtitle mb-2 text-muted">{{ $t("home.ranking") }}</h6>
            </div>
          </div>
          </div>

          <div class="col-4 ">
                <div class="card-info " :class="this.$i18n.locale == 'en' ? 'text-start' : 'text-end'" >
            <div class="card-body">
              <h6 class="card-title">{{ isSubscribed ? userPoints : '-' }}</h6>
              <h6 class="card-subtitle mb-2 text-muted">{{ $t("home.points") }}</h6>
            </div>
          </div>
          </div>

          <div class="col-4 ">
                <div class="card-info " :class="this.$i18n.locale == 'en' ? 'text-start' : 'text-end'" >
            <div class="card-body">
              <h6 class="card-title">{{ userBalance }}</h6>
              <h6 class="card-subtitle mb-2 text-muted">{{ $t("home.coins") }}</h6>
            </div>
          </div>
          </div>


        </div>

        <!-- end user information -->




        <!-- match result -->

        <div class="btn button_match_result mx-auto"><h4>{{$t("newsMatchResults.matchResults")}}</h4></div>
        <div class="carousel-container" @wheel="handleScroll($event)">
          <el-carousel height="230px" arrow="never" ref="carousel">
            <el-carousel-item v-for="match in lastMatches" :key="match.id">
                    <div class="d-flex justify-content-around matchResultCard " >

                          <div class=" text-center ">
                            <img :src="match.homeTeam.flag" alt="hometeam" />
                            <div class="mt-3">
                              <h5 v-if="$i18n.locale == 'en'">{{match.homeTeam.name_en}}</h5>
                              <h5 v-if="$i18n.locale == 'ar'">{{match.homeTeam.name_ar}}</h5>
                              <h5 v-if="$i18n.locale == 'ku'">{{match.homeTeam.name_ku}}</h5>
                              <h5>Home</h5>
                            </div>
                          </div>


                          <div class=" text-center matchResult">
                            <div class="mt-3">
                              <h1 class="result">{{match.homeScore}} - {{match.awayScore}}</h1>
                              <h6 class="text">{{ $t("home.gameEnd") }}</h6>
                            </div>
                          </div>

                          <div class=" text-center ">
                            <img :src="match.awayTeam.flag" alt="awayteam" />
                            <div class="mt-3">
                              <h5 v-if="$i18n.locale == 'en'">{{match.awayTeam.name_en}}</h5>
                              <h5 v-if="$i18n.locale == 'ar'">{{match.awayTeam.name_ar}}</h5>
                              <h5 v-if="$i18n.locale == 'ku'">{{match.awayTeam.name_ku}}</h5>
                              <h5>Away</h5>
                            </div>
                          </div>

                    </div>

                    <!-- <div class="el-prev-next-buttons"> 
                      <div class="el-prev-next-button" @click="goToPrev"> 
                        <i class="el-icon-arrow-left"></i>
                      </div>
                      <div class="el-prev-next-button" @click="goToNext"> 
                        <i class="el-icon-arrow-right"></i>
                      </div>
                    </div> -->

                    
            </el-carousel-item>
          </el-carousel>
        </div>



        <!-- end match result -->








        <!-- question and answer -->


            <div @click="goToRoute('quiz')"  class=" d-flex justify-content-between col-11 mx-auto  align-items-center question_answer" >
              <span style="color:#0D1850">{{ $t("home.questionAnswer") }}</span>
              <img :src="require('@/assets/question_answer.svg')" alt="goalpro" />

            </div>


        <!-- end question and answer -->



    <!-- Head to Head and Play Match -->

    <div class="d-flex justify-content-center px-4 mx-auto align-items-center mb-3">


    <div class="col-6 card-match text-start" @click="goToRoute('head-to-head')">
            <div class="card-body d-flex justify-content-between align-items-center">
              <h5 class="card-title">{{ $t("home.headToHead") }}</h5>
              <img :src="require('@/assets/head_to_head.svg')" height="60" alt="goalpro" />
            </div>
    </div>



  <div class="col-6 card-match text-start" @click="goToRoute('play-match')">
            <div class="card-body d-flex justify-content-between align-items-center">
              <h5 class="card-title">{{ $t("footer.playMatch") }}</h5>
              <img :src="require('@/assets/play_match.svg')" height="60" alt="goalpro" />
            </div>
    </div>


  </div>

  <!-- end Head to Head and Play Match -->




<!-- favorite team  -->

<div class="d-flex  justify-content-between px-4 align-items-center ">
  <h3 class="text-white">{{ $t("home.favourites") }}</h3>
  <el-button @click="goToRoute('favourites')" type="text" class="fav-team-btn" icon="el-icon-plus">{{ $t("home.add") }}</el-button>
</div>


      <!-- favorite team -->
      <!-- <flickity
      class="carousel js-flickity mb-3 px-4"
      ref="flickity"
      :options="flickityOptions"
      >
          <div class="card-fav-team" v-for="fav in favorites" :key="fav.id">
                  <div class="card-body text-center">
                    <img src="@/assets/barcelona.svg" alt="team"/>
                    <h5 class="card-title">Barcelona</h5>
                  </div>
          </div>
    </flickity> -->
    <!-- end favorite team -->





    <vue-horizontal class="horizontal col-11 mx-auto">
      <div class="item" v-for="fav in favorites" :key="fav.id">
        <div class="card-fav-team">
          <div class="card-body text-center">
                    <img height="30" width="30" :src="fav.team.flag" alt="team"/>
                    <h5 class="card-title">{{ fav.team.name_en }}</h5>
                   </div>
          </div>
      </div>
    </vue-horizontal>




        <!-- <PrizesBanner></PrizesBanner> -->


      </div>

      <div class="row mt-5">
          <div class="mt-5"></div>
          <div class="mt-5"></div>
      </div>

      <!-- <CarouselComponent type="banner-home"></CarouselComponent> -->
    </div>

</template>

<script>

import { store, notify } from "../store"
import { Service } from "../services"
// import CarouselComponent from "../components/Carousel.vue"
// import PrizesBanner from "../components/PrizesBanner.vue"
// import Flickity from "vue-flickity";
import VueHorizontal from 'vue-horizontal';

export default {
  name: "HomeComponent",
  components: {
    VueHorizontal,
    // CarouselComponent,
    // PrizesBanner,
    // Flickity,
  },
  data() {
    return {
      ranking: "",
      fullName: "",
      flickityOptions: {
        initialIndex: 0,
        prevNextButtons: false,
        pageDots: false,
        contain: true,
        percentPosition: false,
        groupCells: 2,
        cellAlign: 'left',
  freeScroll: true,

      },
      lastMatches: [],
      favorites: [],
    };
  },
  methods: {
    async getLastMatchResult(){
     let response= await Service.getLastMatches(1,10);
     if (response && response.status === 200) {
        this.lastMatches=response.data.result
        // console.log(response.data.result)
     }
    },
    getFavorite() {
      Service.getUserFavourites().then((res) => {
        this.favorites = res.data;
        // console.log(JSON.stringify(this.favorites));
      });
    },
    goToRoute(route) {
      this.$router.push("/" + route)
      if (route === "play-match") {
        this.$root.$emit('bv', 3);
      }else if (route === "quiz") {
        this.$root.$emit('bv', 2);
      }
    },
    openGetCoins() {
      store.layout.showGetCoins = true
    },
    async getUserInfo(){
      let response = await Service.getUserInfo();
      if (response && response.status === 200) {
        store.userBalance = response.data.balance
        store.userPoints = response.data.currentPoints
        store.subscribed = response.data.subscribed
        this.ranking = response.data.currentRanking
        this.fullName = response.data.firstName + " " + response.data.lastName
      }
      else if (response && response.status === 409) {
        notify(this.$t("error.error"),  this.$t("error.waiting_subscription"), "warning", false)
        store.layout.showLogoutOverlay = true
        setTimeout(async () => {
          await this.getUserInfo()
        }, 5000)
      }
    },

    handleScroll(event){
      if (event.deltaX > 0) {
        this.$refs.carousel.next(); 
      } else if (event.deltaX < 0) {
        this.$refs.carousel.prev(); 
      }
    },

    goToNext() {
      this.$refs.carousel.next(); 
    },

    goToPrev() {
      this.$refs.carousel.prev(); 
    },
  },
  computed: {
    userPoints() {
      return store.userPoints
    },
    userBalance() {
      return store.userBalance
    },
    isSubscribed(){
      return store.subscribed
    }
  },
  async mounted() {
    await this.getUserInfo();
    store.layout.showHeader = true
    store.layout.showUserWallet = false
    store.layout.footerItem = "home"
    store.layout.footerType = "FooterHome"
    store.layout.showGoBack = false
    store.layout.showLanguageSwitch = true

    const carouselContainer = this.$el.querySelector('.carousel-container');
    if (carouselContainer) {
      carouselContainer.addEventListener('wheel', this.handleScroll);
    }

    this.getLastMatchResult();
    this.getFavorite();
  },
  beforeDestroy() {
    const carouselContainer = this.$el.querySelector('.carousel-container');
    if (carouselContainer) {
      carouselContainer.removeEventListener('wheel', this.handleScroll);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.carousel-container {
  overflow-x: auto; 
  overflow-y: hidden; 
  white-space: nowrap; 
}

.el-prev-next-buttons{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 50px;
}

.el-prev-next-button{
  border: none;
    outline: 0;
    padding: 0;
    margin: 0;
    height: 80px;
    width: 80px;
    cursor: pointer;
    transition: .3s;
    border-radius: 50%;
    background-color: rgba(31, 45, 61, .11);
    color: #fff;
    z-index: 10;
    transform: translateY(-50%);
    text-align: center;
    font-size: 12px;
}

.horizontal {
  --count: 1;
  --gap: 16px;
  --margin: 24px;

}

@media (min-width: 640px) {
  .horizontal {
    --count: 2;
  }
}

@media (min-width: 768px) {
  .horizontal {
    --count: 3;
    --margin: 0;
  }
}

@media (min-width: 1024px) {
  .horizontal {
    --count: 4;
  }
}

@media (min-width: 1280px) {
  .horizontal {
    --gap: 24px;
    --count: 6;
  }
}


@media (max-width: 767.98px) {
  .item {
    width: 150px ;
    /* padding: 0 calc(var(--gap) / 2); */
  }

  .item:only-child {
    width: calc((100% - (var(--margin) * 2) + var(--gap)) / var(--count) + var(--margin) * 2 - var(--gap));
  }

  .horizontal {
    margin: 0 calc(var(--margin) * -1);
  }

  .horizontal >>> .v-hl-container {
    scroll-padding: 0 calc(var(--margin) - (var(--gap) / 2));
  }

  .horizontal >>> .v-hl-btn {
    display: none;
  }
}

@media (min-width: 768px) {
  .item {
    width: calc((100% - ((var(--count) - 1) * var(--gap))) / var(--count));
    margin-right: var(--gap);
  }
}


.el-carousel__container{
  height: 100px !important;
}

.el-carousel__item h3 {
    color: #ffffff;
  }

  .el-carousel__item {
    background: url('../assets/background_match_result.svg') center center no-repeat;
    background-size: cover;
  }

  .matchResultCard{
    color: white !important;
  }

  .matchResultCard img{
    height: 45px !important;
    margin: 0 auto;
  }

  .matchResult .result{
    font-size: 80px !important;
  }
  .matchResult .text{
    font-size: 20px !important;
    color: #C1DEFF !important;
  }


  .button_match_result{
    background:#15194D !important;
    color: white;
    border-bottom-left-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
    /* margin-top: 20px !important; */
    /* position: absolute; */
    z-index: 4;
    /* top: 271px;
    right: 145px; */
  }

.home-main-content {
  background-color: #0C1538;
  padding-top: 5px;
  margin-bottom: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.subscribe {
  font-size: 24px;
  color: #ffffff;
  background: #445C87;
  padding: 6px 0px;
  border-radius: 6px;
  margin: 8px 10px 0px 10px;
}

.user-info-wallet {
  padding: 10px 10px;
  border-radius: 6px;
  background-color: #2A3757;
  margin: 5px 10px 15px 10px;
}


.carousel-cell {
  width: 100%; /* half-width */
  height: 160px;
  margin:0px 10px !important;
}

.flickity-slider {
  transform: translateX(10px) !important;
}

.buying_coin{
  background: linear-gradient( #F4C8AC 0%, #CABBB2 100%);
  border-radius: 15px !important;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  padding:2px 15px !important;

}

.buying_coin span{
  font-size: 17px;
}

.question_answer{
  background: linear-gradient( #F4C8AC 0%, #CABBB2 100%);
  border-radius: 15px !important;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  padding:20px !important;
  /* width: 96% !important; */
  margin-bottom: 10px !important;
}


.question_answer span{
  font-size: 30px !important;
}



.user-name {
  color: #a9fb05;
  height: 40px;
  font-size: 28px;

}

.username_and_coin img{
  line-height: 1.1  !important;
  width: 20px;
  margin: 0 4px !important;
  transform: translate(0px, -2px) !important;
}

.user-name-text{
  font-size: 20px !important;
  color: white;
}

.user-name i {
  margin-left: 5px;
  font-size: 22px;
}


.card-info{
  background: #182252 !important;
  border-radius: 15px !important;
  color:#C1DEFF !important;
  margin:0px 5px !important;
  cursor: pointer;
}


.card-info .card-body{
  padding: 4px 23px 0px 20px !important;
}


.card-info .card-title{
  color:#C1DEFF !important
}
.card-info .card-subtitle{
  color:#C1DEFF !important
}



/* card match */

.card-match{
  background: linear-gradient(#213182,#111941) !important;
  border-radius: 15px !important;
  color:#C1DEFF !important;
  margin:0px 7px !important;
  cursor: pointer !important;
}


.card-match .card-body{
  padding: 25px 15px 10px 15px !important;

  /* padding:8px !important; */
}


.card-match .card-title{
  color:#ffffff !important;
  font-size: 20px !important;
  width: 70px;
}
.card-match .card-subtitle{
  color:#f7f7f7 !important
}


/* card favorite team */
.card-fav-team{
  background: linear-gradient(#213182,#111941) !important;
  border-radius: 15px !important;
  color:#C1DEFF !important;
  /* margin:0px 7px !important; */
  cursor: pointer !important;
  height: 84px !important;
}

@media screen and (max-width: 767px) {
  .card-fav-team{
    margin:0px 7px !important;
  }
}


.card-fav-team .card-body{
  /* padding: 30px 15px 20px 15px !important; */
  height: 100px !important;
  /* padding:15px 30px 15px 30px !important; */
}


.card-fav-team .card-title{
  color:#ffffff !important;
  font-size: 15px !important;
  margin-top: 5px !important;
}
.card-fav-team .card-subtitle{
  color:#f7f7f7 !important
}


.fav-team-btn{
  color:#D74EF8 !important;
  font-size: 16px ;
}



.user-wallet {
  color: #a9fb05;
  display: flex;
  justify-content: space-around;
  margin-top: 0px;
}
.user-wallet-content {
  padding: 10px 10px 0px 10px;

  font-size: 35px;
}

.user-wallet-watch-ads {
  font-family: 'Rabar_031';
  font-size: 10px;
  color: #ffffff;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  border: solid 4px #232C40;
  border-radius: 7px;
  background: #445C87;
  margin: 5px 0px;
  padding: 0px 15px;
  padding-right: 8px;
  margin-top: 10px;

}

.user-wallet-watch-ads .ads-text {
  max-width: 70px;
  display: flex;
}

.user-wallet-watch-ads .ads-play-icon {
  display: flex;
  margin-left: 10px;
}

.user-wallet-number {
  line-height: 32px;
}
.user-info-wallet-separator {
  border-bottom: #6f91ff30 1px solid;
  height: 1px;
  display: flex;

}

.user-wallet-separator {
  border-left: #999 1px solid;
  height: 40px;
  display: flex;
  align-self: center;
}
.user-wallet-text {
  color: white;
  font-size: 15px;
}

.dashboard {
  padding: 2px 15px;
  margin-bottom: 30px;
}
.play-match {
  padding: 10px 5px;
  background-color: #a9fb05;
  color: #2a3757;
  border-radius: 6px;
  display: flex;
  margin-top: 5px;
  margin-bottom: 20px;
}

.logo-play-match {
  align-self: center;
  margin-left: 10px;
  display: flex;
}

.text-play-match {
  align-self: center;
  margin-left: 15px;

  font-size: 42px;
}

.play-match i {
  font-size: 28px;
  margin-left: auto;
  align-self: center;
  padding-right: 5px;
}

.daily-quiz,
.head-to-head {
  padding: 8px 5px;
  background-color: #2a3757;
  color: #ffffff;
  border-radius: 8px;
  display: flex;
  margin-top: 5px;
  margin-bottom: 10px;
}

.head-to-head {
  margin-bottom: 30px;
  margin-top: 15px;
}

.logo-daily-quiz,
.logo-head-to-head {
  align-self: center;
  margin-left: 10px;
  display: flex;
}

.logo-daily-quiz img,
.logo-head-to-head img,
.logo-play-match img {
  width: 30px;
}

.text-daily-quiz,
.text-head-to-head {
  align-self: center;
  margin-left: 15px;
  font-size: 24px;
}

.daily-quiz i,
.head-to-head i {
  font-size: 28px;
  margin-left: auto;
  align-self: center;
  padding-right: 5px;
}

.home-banner {
  padding: 0 10px;
  margin-bottom: 0px;
}

.home-banner img {
  border-radius: 8px;
  width: 100%;
  height: 180px;
}


</style>
